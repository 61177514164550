

// export default TransactionsPage;
import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase'; // Make sure to adjust the import paths
import { collection, getDocs, doc, getDoc, query, orderBy } from 'firebase/firestore';
import "../style/Transaction.css";
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const TransactionsPage = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [walletBalance, setWalletBalance] = useState(0); // State to hold wallet balance

    const user = auth.currentUser;

    useEffect(() => {
        if (user) {
            fetchTransactions();
            fetchWalletBalance(); // Fetch wallet balance
        } else {
            setError('User not logged in. Please log in.');
            setLoading(false);
        }
    }, [user]);

    // Function to fetch wallet balance
    const fetchWalletBalance = async () => {
        try {
            const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
            const walletSnap = await getDoc(walletRef);

            if (walletSnap.exists()) {
                setWalletBalance(walletSnap.data().balance); // Set wallet balance
            } else {
                setError('Failed to fetch wallet balance.');
            }
        } catch (error) {
            console.error('Error fetching wallet balance:', error.message);
            setError('Failed to fetch wallet balance.');
        }
    };

    // Function to fetch transactions
    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const transactionsRef = collection(db, 'users', user.uid, 'wallet', 'walletDetails', 'transactions');
            const q = query(transactionsRef, orderBy('timestamp', 'desc'));
            const querySnapshot = await getDocs(q);
            const fetchedTransactions = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTransactions(fetchedTransactions);
        } catch (error) {
            console.error('Error fetching transactions:', error.message);
            setError('Failed to fetch transactions. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    // Function to get transaction type count (deposit/withdrawal)
    const getTransactionTypeCount = (type) => {
        return transactions.filter(transaction => transaction.type === type).length;
    };

    // Function to download the transaction data as CSV
    const downloadCSV = () => {
        const csvRows = [
            ["Date", "Type", "Amount (₹)", "Balance Before (₹)", "Balance After (₹)"], // CSV headers
            ...transactions.map(transaction => [
                new Date(transaction.timestamp.toDate()).toLocaleString(),
                transaction.type,
                transaction.amount,
                transaction.balanceBefore,
                transaction.balanceAfter
            ])
        ];

        const csvContent = csvRows.map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "transaction_history.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="transactions-page">
            <Header />
            <div className="transactions-container">
                <Sidebar />
                <div className="main-content">
                    <h1 className="page-title">Transaction History</h1>
                    {error && <p className="error-message">{error}</p>}
                    {loading ? (
                        <p className="loading-message">Loading transactions...</p>
                    ) : transactions.length === 0 ? (
                        <p className="loading-message">No transactions found.</p>
                    ) : (
                        <>
                            <div className="analytics-section">
                                <div className="analytics-card">
                                    <h3>Wallet Balance</h3>
                                    <p>₹{walletBalance.toFixed(2)}</p>
                                </div>
                                <div className="analytics-card">
                                    <h3>Total Transactions</h3>
                                    <p>{transactions.length}</p>
                                </div>
                                <div className="analytics-card">
                                    <h3>Deposits</h3>
                                    <p>{getTransactionTypeCount('deposit')}</p>
                                </div>
                                {/* <div className="analytics-card">
                                    <h3>Withdrawals</h3>
                                    <p>{getTransactionTypeCount('withdrawal')}</p>
                                </div> */}
                            </div>
                            <button onClick={downloadCSV} className="download-csv-button">
                                Download CSV
                            </button>
                            <table className="transactions-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Amount (₹)</th>
                                        <th>Balance Before (₹)</th>
                                        <th>Balance After (₹)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.map((transaction) => (
                                        <tr key={transaction.id}>
                   <td>{transaction.timestamp ? new Date(transaction.timestamp.toDate()).toLocaleString() : 'NA'}</td>
<td>{transaction.type ? transaction.type : 'NA'}</td>
<td>{transaction.amount !== undefined && transaction.amount !== null ? transaction.amount.toFixed(2) : 'NA'}</td>
<td>{transaction.balanceBefore !== undefined && transaction.balanceBefore !== null ? transaction.balanceBefore.toFixed(2) : 'NA'}</td>
<td>{transaction.balanceAfter !== undefined && transaction.balanceAfter !== null ? transaction.balanceAfter.toFixed(2) : 'NA'}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransactionsPage;