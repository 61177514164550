// import React, { useState } from 'react';
// import {addDoc, collection } from 'firebase/firestore'; 
// import { db, auth } from '../firebase';
// import '../style/ContactUs.css';
// import Header from '../components/Header';  
// import Sidebar from '../components/Sidebar'; 

// const ContactUs = () => {
//     // State to manage form input values
//     const [formData, setFormData] = useState({
//       name: '',
//       email: '',
//       phone: '',
//       message: '',
//     });
  
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [status, setStatus] = useState('');
  
//     // Handle input change and update state
//     const handleChange = (e) => {
//       const { name, value } = e.target;
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     };
  
//     // Handle form submission
//     const handleSubmit = async (e) => {
//       e.preventDefault();
//       setIsSubmitting(true);
//       setStatus('');
  
//       try {
//         // Add contact form data to Firestore
//         const docRef = await addDoc(collection(db, 'contactMessages'), {
//           name: formData.name,
//           email: formData.email,
//           phone: formData.phone,
//           message: formData.message,
//           timestamp: new Date(),
//         });
  
//         console.log("Document written with ID: ", docRef.id);
  
//         setStatus('Message sent successfully!');
//         setFormData({
//           name: '',
//           email: '',
//           phone: '',
//           message: '',
//         });
//       } catch (error) {
//         setStatus('Error sending message. Please try again later.');
//         console.error("Error adding document: ", error);
//       } finally {
//         setIsSubmitting(false);
//       }
//     };
  
//     return (
//       <div className="contact-us">
//         <Header />
//         <div className="contact-container">
//           <Sidebar />
//           <div className="main-content">
//             <h1>Get in Touch</h1>
//             <p>Need assistance? We're just a message away.</p>
  
//             <form onSubmit={handleSubmit} className="contact-form">
//               <div className="form-group">
//                 <label htmlFor="name">Name</label>
//                 <input
//                   type="text"
//                   id="name"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
  
//               <div className="form-group">
//                 <label htmlFor="email">Email</label>
//                 <input
//                   type="email"
//                   id="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
  
//               <div className="form-group">
//                 <label htmlFor="phone">Phone Number</label>
//                 <input
//                   type="text"
//                   id="phone"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
  
//               <div className="form-group">
//                 <label htmlFor="message">Message</label>
//                 <textarea
//                   id="message"
//                   name="message"
//                   value={formData.message}
//                   onChange={handleChange}
//                   required
//                 ></textarea>
//               </div>
  
//               <button type="submit" disabled={isSubmitting}>Send</button>
  
//               {status && <p className="status-message">{status}</p>}
//             </form>
//           </div>
//         </div>
//       </div>
//     );
//   };
  
//   export default ContactUs;

import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore'; 
import { db, Storage, auth } from '../firebase'; // Fixed "Storage" to "storage" to match the Firebase import
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useLocation } from 'react-router-dom';
import '../style/ContactUs.css';
import Header from '../components/Header';  
import Sidebar from '../components/Sidebar'; 

const ContactUs = () => {
  // Extract shopId from the URL query parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shop');  // Get the shopId from the query parameter


  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }

  // State to manage form input values
  const [formData, setFormData] = useState({
    message: '',
    image: null,
    video: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState('');
  
  // Handle input change and update state
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Handle text fields (like message)
    if (name === 'message') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Handle file fields (like image and video)
    if (files && files[0]) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Handle the file uploads safely
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus('');
  
    try {
      // Ensure that shopId exists
      // if (!shopId) {
      //   throw new Error("Shop ID is missing");
      // }

      // Upload image and video to Firebase Storage
      const uploadedFiles = await uploadFilesToStorage(user.uid);

      // Add message and file URLs to Firestore for the specific shop
      const docRef = await addDoc(collection(db, 'users', user.uid, 'contactMessages'), {
        message: formData.message,
        imageUrl: uploadedFiles.imageUrl,
        videoUrl: uploadedFiles.videoUrl,
        timestamp: new Date(),
      });
  
      console.log("Document written with ID: ", docRef.id);
  
      setStatus('Message sent successfully!');
      setFormData({
        message: '',
        image: null,
        video: null,
      });
    } catch (error) {
      setStatus('Error sending message. Please try again later.');
      console.error("Error adding document: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to upload files to Firebase Storage
  const uploadFilesToStorage = async (userId) => {
    const uploadedFiles = { imageUrl: '', videoUrl: '' };

    // Check if there's an image before attempting to upload
    if (formData.image) {
      const imageRef = ref(Storage, `users/${userId}/contact-images/${formData.image.name}`);
      const imageUploadTask = uploadBytesResumable(imageRef, formData.image);
      await imageUploadTask;
      uploadedFiles.imageUrl = await getDownloadURL(imageRef);
    }

    // Check if there's a video before attempting to upload
    if (formData.video) {
      const videoRef = ref(Storage, `users/${userId}/contact-videos/${formData.video.name}`);
      const videoUploadTask = uploadBytesResumable(videoRef, formData.video);
      await videoUploadTask;
      uploadedFiles.videoUrl = await getDownloadURL(videoRef);
    }

    return uploadedFiles;
  };

  return (
    <div className="contact-us">
      <Header />
      <div className="contact-container">
        <Sidebar />
        <div className="main-content">
          <h1>Get in Touch</h1>
          <p>Need assistance? We're just a message away.</p>

          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}  // Bind the textarea value to state
                onChange={handleChange}    // Correctly update state on change
                required
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="image">Upload Image</label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="video">Upload Video</label>
              <input
                type="file"
                id="video"
                name="video"
                accept="video/*"
                onChange={handleChange}
              />
            </div>

            <button type="submit" disabled={isSubmitting}>Submit</button>

            {status && <p className="status-message">{status}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
