import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS } from 'chart.js/auto';
import DatePicker from 'react-datepicker'; // Import DatePicker
import { Map, Marker, Overlay } from 'pigeon-maps';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import '../style/Dashboard.css';

const Dashboard = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const shop = params.get('shop');

  const [orders, setOrders] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [startDate, setStartDate] = useState(null); // For start date
  const [endDate, setEndDate] = useState(null); // For end date
  const [BrandInfo, setBrandInfo] = useState('');

  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }

  useEffect(() => {
    const fetchOrders = async () => {
      // if (!shop) {
      //   setError('Shop information is missing.');
      //   setLoading(false);
      //   return;
      // }

      try {
        const ordersRef = collection(db, 'users', user.uid, 'orders');
        const ordersSnapshot = await getDocs(ordersRef);
        const ordersData = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setOrders(ordersData);

        // Collect customer locations while checking for valid shipping_address
        const locations = ordersData
          .map(order => {
            const billingCity = order.billing_city;

            if (billingCity && billingCity.latitude && billingCity.longitude) {
              return {
                lat: billingCity.latitude,
                lng: billingCity.longitude,
                address: billingCity,
              };
            }
            return null;
          })
          .filter(location => location !== null);

        setCustomerLocations(locations);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('An error occurred while fetching orders.');
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user]);


  useEffect(() => {
    const fetchBrandInfo = async () => {
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setBrandInfo({
          brandName: userData.brandName || 'Default Brand Name',
        });
      } else {
        console.log('No such document!');
      }
    };

    fetchBrandInfo();
  }, [user]); // Re-fetch if shopId changes

  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const productCounts = {};
  const productRevenue = {};
  const totalOrdersByDate = {}; // To store the total revenue by date

  orders.forEach(order => {
    if (Array.isArray(order.order_items)) {
      order.order_items.forEach(item => {
        const productName = item.name;
        const sellingPrice = item.selling_price || 0;
        const totalCost = item.total_cost || 0;
        const netRevenue = sellingPrice - totalCost;

        productCounts[productName] = (productCounts[productName] || 0) + 1;
        productRevenue[productName] = (productRevenue[productName] || 0) + netRevenue;

        // Aggregate orders by date
        const orderDate = new Date(order.created_at * 1000); // Assuming timestamp is in Firestore as seconds
        const formattedDate = orderDate.toLocaleDateString(); // Format as YYYY-MM-DD

        totalOrdersByDate[formattedDate] = (totalOrdersByDate[formattedDate] || 0) + netRevenue;
      });
    }
  });

  // Prepare data for Product Breakdown (Pie) and Product Revenue Breakdown (Bar)
  const productTypes = Object.keys(productCounts);
  const pieChartData = {
    labels: productTypes,
    datasets: [
      {
        label: 'Product Breakdown',
        data: productTypes.map(type => productCounts[type]),
        backgroundColor: ['#00589c', '#3ac0da', '#1891c3', '#004d73', '#3dc6c3', '#50e3c2', '#0097A7','#006e8e',
  '#0089a3', '#56c9d9', '#006b7d', '#4bc4d3', '#2a7e8c', '#0096a8'],
      },
    ],
  };

  const barChartData = {
    labels: productTypes,
    datasets: [
      {
        label: 'Product Revenue',
        data: productTypes.map(type => productRevenue[type] || 0),
        backgroundColor: ['#00589c', '#3ac0da', '#1891c3', '#004d73', '#3dc6c3', '#50e3c2', '#0097A7','#006e8e',
          '#0089a3', '#56c9d9', '#006b7d', '#4bc4d3', '#2a7e8c', '#0096a8'],
      },
    ],
  };

  // Prepare data for Total Orders by Date (Line Chart)
// Prepare data for Total Orders by Date (Line Chart)
// Initialize a new object for the revenue calculation

orders.forEach(order => {
  if (Array.isArray(order.order_items)) {
    order.order_items.forEach(item => {
      const sellingPrice = parseFloat(item.selling_price) || 0; // Convert to a float for calculation

      // Check if 'createdAt' is a Firestore timestamp and convert it to a JavaScript Date object
      let orderDate = order.createdAt;

      if (orderDate && orderDate.toDate) {
        // If it's a Firestore Timestamp, use toDate() to convert it to a JS Date
        orderDate = orderDate.toDate();
      } else {
        // If it's already a JS Date, no need for conversion
        orderDate = new Date(orderDate);
      }

      const formattedDate = orderDate.toLocaleDateString(); // Format as YYYY-MM-DD

      totalOrdersByDate[formattedDate] = (totalOrdersByDate[formattedDate] || 0) + sellingPrice;
    });
  }
});


// Prepare data for Line chart (Total Orders by Date)
const totalOrdersDates = Object.keys(totalOrdersByDate);
const lineChartData = {
  labels: totalOrdersDates,
  datasets: [
    {
      label: 'Total Order Revenue by Date',
      data: totalOrdersDates.map(date => totalOrdersByDate[date]),
      fill: false,
      backgroundColor: '#00bfff',
      borderColor: '#00bfff',
      borderWidth: 2,
    },
  ],
};


  // Filter orders based on selected date range
  const filteredOrders = orders.filter(order => {
    if (startDate && endDate) {
      const orderDate = new Date(order.createdAt * 1000);
      return orderDate >= startDate && orderDate <= endDate;
    }
    return true;
  });

  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-content">
        <h1>Welcome to {BrandInfo.brandName}'s Dashboard</h1>


          {/* Date Range Picker
          <div className="date-picker-container">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
              dateFormat="yyyy/MM/dd"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              dateFormat="yyyy/MM/dd"
            />
          </div> */}

          {/* Cards Section */}
          <div className="card-container">
            <div className="card">
              <Link to={'/selectTshirt'}><p>Create Product</p></Link>
            </div>
            <div className="card">
              <Link to={'/Book-a-sample'}><p>Book a Sample</p></Link>
            </div>
            <div className="card">
              <Link to={'/OrdersPage'}><p>Orders</p></Link>
            </div>
          </div>

          <div className="chart">
              <h3>Total Orders by Date</h3>
              <Line data={lineChartData} />
            </div>

          {/* Charts Section */}
          <div className="chart-container">

            <div className="chart">
              <h3>Product Breakdown</h3>
              <Pie data={pieChartData} />
            </div>

            <div className="chart">
              <h3>Product Revenue Breakdown</h3>
              <Bar data={barChartData} />
            </div>

           
         

          {/* Map Section */}
          <div className="chart">
  <h4 align><span className="d1">Places You Reached</span></h4>
  <div className="map-container">
    <Map height={400} defaultCenter={[20.5937, 78.9629]} defaultZoom={4}>
      {customerLocations.map((location, index) => (
        <Marker
          key={index}
          width={50}
          anchor={[location.lat, location.lng]}
          onClick={() => setSelectedLocation(location)}
        />
      ))}
      {selectedLocation && (
        <Overlay anchor={[selectedLocation.lat, selectedLocation.lng]} offset={[0, 0]}>
          <div className="popup">{selectedLocation.address}</div>
        </Overlay>
      )}
    </Map>

    {/* Coming Soon Overlay */}
    <div className="coming-soon-overlay">
      <h2>Coming Soon</h2>
    </div>
  </div>
</div>


        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import Header from '../components/Header';
// import Sidebar from '../components/Sidebar';
// import { Pie, Bar, Line } from 'react-chartjs-2';
// import { CategoryScale, Chart as ChartJS } from 'chart.js/auto';
// import DatePicker from 'react-datepicker'; // Import DatePicker
// import { Map, Marker, Overlay } from 'pigeon-maps';
// import { db } from '../firebase';
// import { collection, getDocs } from 'firebase/firestore';
// import '../style/Dashboard.css';


// const Dashboard = () => {
//   const [selectedLocation, setSelectedLocation] = useState(null);

//   // Dummy Data for Charts
//   const dummyProductCounts = {
//     TShirt: 50,
//     Hoodie: 30,
//     Cap: 20,
//   };

//   const dummyProductRevenue = {
//     TShirt: 5000,
//     Hoodie: 3000,
//     Cap: 1500,
//   };

//   const dummyTotalOrdersByDate = {
//     '2024-12-01': 700,
//     '2024-12-02': 900,
//     '2024-12-03': 650,
//   };

//   // Dummy Data for Map
//   const dummyCustomerLocations = [
//     { lat: 28.6139, lng: 77.209, address: 'New Delhi, India' },
//     { lat: 12.9716, lng: 77.5946, address: 'Bangalore, India' },
//     { lat: 19.076, lng: 72.8777, address: 'Mumbai, India' },
//   ];

//   // Prepare Chart Data
//   const pieChartData = {
//     labels: Object.keys(dummyProductCounts),
//     datasets: [
//       {
//         label: 'Product Breakdown',
//         data: Object.values(dummyProductCounts),
//         backgroundColor: ['#00589c', '#3ac0da', '#50e3c2'],
//       },
//     ],
//   };

//   const barChartData = {
//     labels: Object.keys(dummyProductRevenue),
//     datasets: [
//       {
//         label: 'Product Revenue',
//         data: Object.values(dummyProductRevenue),
//         backgroundColor: ['#00589c', '#3ac0da', '#50e3c2'],
//       },
//     ],
//   };

//   const lineChartData = {
//     labels: Object.keys(dummyTotalOrdersByDate),
//     datasets: [
//       {
//         label: 'Total Order Revenue by Date',
//         data: Object.values(dummyTotalOrdersByDate),
//         fill: false,
//         backgroundColor: '#00bfff',
//         borderColor: '#00bfff',
//         borderWidth: 2,
//       },
//     ],
//   };

//   return (
//     <div className="dashboard">
//       <Header />
//       <div className="dashboard-container">
//         <Sidebar />
//         <div className="main-content">
//           <h1>Dashboard Overview</h1>

//           {/* Cards Section */}
//           <div className="card-container">
//             <div className="card">
//               <Link to="/selectTshirt"><p>Create Product</p></Link>
//             </div>
//             <div className="card">
//               <Link to="/placeorder"><p>Create Order</p></Link>
//             </div>
//             <div className="card">
//               <Link to="/OrdersPage"><p>Orders</p></Link>
//             </div>
//           </div>

//           {/* Charts Section */}
//           <div className="chart">
//             <h3>Total Orders by Date</h3>
//             <Line data={lineChartData} />
//           </div>

//           <div className="chart-container">
//             <div className="chart">
//               <h3>Product Breakdown</h3>
//               <Pie data={pieChartData} />
//             </div>

//             <div className="chart">
//               <h3>Product Revenue Breakdown</h3>
//               <Bar data={barChartData} />
//             </div>
//           </div>

//           {/* Map Section */}
//           <div className="chart">
//             <h4>Places You Reached</h4>
//             <div className="map-container">
//               <Map height={400} defaultCenter={[20.5937, 78.9629]} defaultZoom={4}>
//                 {dummyCustomerLocations.map((location, index) => (
//                   <Marker
//                     key={index}
//                     width={50}
//                     anchor={[location.lat, location.lng]}
//                     onClick={() => setSelectedLocation(location)}
//                   />
//                 ))}
//                 {selectedLocation && (
//                   <Overlay anchor={[selectedLocation.lat, selectedLocation.lng]} offset={[0, 0]}>
//                     <div className="popup">{selectedLocation.address}</div>
//                   </Overlay>
//                 )}
//               </Map>
//               <div className="coming-soon-overlay">
//                 <h2>Coming Soon</h2>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;
