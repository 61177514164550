import React, { useState, useEffect } from "react";
import {doc, getDoc} from "firebase/firestore";
import { db, auth } from '../firebase';
import "../style/Sidebar.css";
import {
  FaTimes,
  FaBars,
  FaChartLine,
  FaShapes,
  FaTshirt,
  FaRocket,
  FaShoppingCart,
  FaCheckCircle,
  FaMoneyCheckAlt,
  FaFileContract,
  FaLock,
  FaUndo,
  FaShippingFast,
  FaEnvelope,
  FaPhoneAlt,
  FaCog,
  FaSearch,
  FaStore,
  FaShopify,
  FaTags
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFounderOpen, setIsFounderOpen] = useState(false);
  const [userShop, setUserShop] = useState(null);
  
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }

  // const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);
  // const shop = queryParams.get('shop');

  // State to track if it's desktop or mobile
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  // Adjust layout on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    const fetchUserShop = async () => {
      // Assuming db is the Firestore instance and user.uid is the current user's ID
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      // if (!userDoc.exists()) {
      //   toast.error("User data not found.");
      //   return;
      // }
  
      const userData = userDoc.data();
      const userShop = userData.shop;
      setUserShop(userShop); // Update the userShop state
      
      // setLoading(false); // Mark as done loading
    };
  
    fetchUserShop();
  }, [user.uid]); // Fetch only when the user.uid changes

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleFounderInfo = () => {
    setIsFounderOpen(!isFounderOpen);
  };

  return (
    <>
      {/* Hamburger Menu (Mobile Only) */}
      {!isDesktop && (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <FaBars />
        </div>
      )}

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen || isDesktop ? "open" : "closed"}`}>
        {/* Close Button (Mobile Only) */}
        <div className="sidebar-header">
          <div className="logo">
            <Link to={'/dashboard'}>
              <img src="logo.png" alt="Logo" />
            </Link>
          </div>
          {!isDesktop && (
            <div className="close-icon" onClick={toggleSidebar}>
              <FaTimes />
            </div>
          )}
        </div>

        {/* Menu Items */}
        <div className="menu">
          <div className="menu-item">
            <Link to={'/dashboard'}>
              <FaChartLine className="icon" />
              <span>Dashboard</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/SelectTshirt'}>
            <FaTshirt className="icon" />
              <span>Create Product</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/Products'}>
              <FaShapes className="icon" />
              <span>Products</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={'/Book-a-sample'}>
              <FaSearch className="icon" />
              <span>Book a Sample</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/branding'}>
              <FaTags className="icon" />
              <span>Branding</span>
            </Link>
          </div>
           
          <div className="menu-item">
            <Link to={'/create-order-list'}>
              <FaShoppingCart className="icon" />
              <span>Create Order</span>
            </Link>
          </div>


          {/* <div className="menu-item">
            <Link to={'/OrdersPage'}>
              <FaShopify className="icon" />
              <span>Shopify Orders</span>
            </Link>
          </div> */}


     {userShop && (
            <div className="menu-item">
              <Link to={'/OrdersPage'}>
                <FaShopify className="icon" />
                <span>Shopify Orders</span>
              </Link>
            </div>
          )}

          <div className="menu-item">
            <Link to={'/PaidOrders'}>
              <FaCheckCircle className="icon" />
              <span>Confirmed Orders</span>
            </Link>
          </div>



          <div className="menu-item">
            <Link to={'/Transaction'}>
              <FaMoneyCheckAlt className="icon" />
              <span>Transactions</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/store-connect'}>
              <FaStore className="icon" />
              <span>My Store</span>
            </Link>
          </div>


          <div className="menu-item">
            <Link to={'/Setting'}>
              <FaCog className="icon" />
              <span>Settings</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/privacy-policy'}>
              <FaLock className="icon" />
              <span>Privacy Policy</span>
            </Link>
          </div>

          <div className="menu-item">
          <Link to={'/shipping-policy'} >
            <FaShippingFast className="icon" />
            <span>Shipping and Delivery</span>
          </Link>
        </div>

           <div className="menu-item">
            <Link to={'/terms-and-conditions'}>
              <FaFileContract className="icon" />
              <span>Terms and Conditions</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={'/contact-us'}>
              <FaEnvelope className="icon" />
              <span>Contact Us</span>
            </Link>
          </div>
          <div className="menu-item">
            <div onClick={toggleFounderInfo} className="founder-link">
              <FaPhoneAlt className="icon" />
              <span>Call a Founder</span>
            </div>
            {isFounderOpen && (
              <div className="founder-info">
                <p>Name: Rajprit Sahni</p>
                <p>Phone: +91 81040 38187</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

