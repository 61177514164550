import React, { useState, useEffect } from 'react';
import BACKEND_URL from '../config'
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import {doc, getDoc} from "firebase/firestore";
import { db, auth } from '../firebase';
const StoreUrlForm = () => {
  const [storeUrl, setStoreUrl] = useState('');
  const [error, setError] = useState('');
 const [userShop, setUserShop] = useState(null);
  // Validate and format the Shopify store URL
  const validateAndFormatUrl = (url) => {
    let formattedUrl = url.trim().toLowerCase();
    // Add https:// if the URL doesn't start with it
    if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = 'https://' + formattedUrl;
    }
    // Ensure it's a valid URL
    try {
      new URL(formattedUrl);
      return formattedUrl;
    } catch {
      throw new Error('Invalid URL format');
    }
  };

  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }


  useEffect(() => {
    const fetchUserShop = async () => {
      // Assuming db is the Firestore instance and user.uid is the current user's ID
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      // if (!userDoc.exists()) {
      //   toast.error("User data not found.");
      //   return;
      // }
  
      const userData = userDoc.data();
      const userShop = userData.shop;
      setUserShop(userShop); // Update the userShop state
      
      // setLoading(false); // Mark as done loading
    };
  
    fetchUserShop();
  }, [user.uid]); // Fetch only when the user.uid changes

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
    try {
      // Validate and format the Shopify store URL
      const formattedUrl = validateAndFormatUrl(storeUrl);
      const shop = new URL(formattedUrl).hostname;
      // **Use environment variable for backend URL**
      const backendUrl = BACKEND_URL;
      ;
      if (!backendUrl) {
        throw new Error('Backend URL is not defined');
      }
      // Redirect to your backend's auth route (which will redirect to Shopify)
      window.location.href = `${backendUrl}/api/auth?shop=${shop}`;
    } catch (error) {
      // Handle URL format error or other issues
      if (error.message === 'Invalid URL format') {
        setError('Please enter a valid Shopify store URL');
      } else {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
      }
    }
  };
  
    return (

      <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
      <div style={styles.container}>
        <h1 style={styles.header}>Welcome to Unitee</h1>
        {userShop && (
              <p style={styles.shopName}>Connected Shopify Store: {userShop}</p>
            )}
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            value={storeUrl}
            onChange={(e) => setStoreUrl(e.target.value)}
            placeholder="Enter your Shopify store URL"
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>Connect</button>
          {error && <p style={styles.error}>{error}</p>}
        </form>
      </div>
      </div></div>
      </div>
    );
  };
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f9f9f9', // Light background color
      padding: '20px',
    },
    header: {
      fontSize: '2.5em',
      fontWeight: '700',
      color: '#333',
      marginBottom: '40px', // Space between the header and the form
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white', // White background for the form
      padding: '40px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    input: {
      padding: '10px',
      width: '300px',
      marginBottom: '20px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      fontSize: '1em',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#000',
      color: 'white',
      fontSize: '1em',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease', // Smooth hover effect
    },
    buttonHover: {
      backgroundColor: '#45a049', // Darker shade for hover
    },
    error: {
      color: 'red',
      marginTop: '10px',
    }
  };
  
  export default StoreUrlForm;