import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../firebase.js';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import Sidebar from '../components/Sidebar.jsx';
import Header from '../components/Header.jsx';

const SettingsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get('shop'); // Assuming shop name is passed in the URL as a query parameter

    const [ifscCode, setIfscCode] = useState('');
    const [branch, setBranch] = useState('');
    const [name, setName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(true);


        const user = auth.currentUser;
        if (!user) {
          throw new Error("User not logged in");
        }

    // Fetch existing shop details if available
    useEffect(() => {
        const fetchShopDetails = async () => {
            try {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    // You can prefill the form with existing account info if it exists
                    const userData = userDoc.data();
                    setName(userData.name || '');
                    setIfscCode(userData.ifscCode || '');
                    setBranch(userData.branch || '');
                    setAccountNumber(userData.accountNumber || '');
                }

                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching shop details:', err);
                setIsLoading(false);
            }
        };

        if (user) {
            fetchShopDetails();
        } else {
            setError('user not found.');
            setIsLoading(false);
        }
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!user) {
            setError('User name is required.');
            return;
        }

        try {
            const userRef = doc(db, 'users', user.uid);

            // Update the shop document with the new account information
            await updateDoc(userRef, {
                name,
                ifscCode,
                branch,
                accountNumber,
            });

            setSuccess('Account info updated successfully!');
        } catch (err) {
            console.error('Error updating account info:', err);
            setError('An error occurred. Please try again.');
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="products-page">
            <Header />
            <div className="products-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Account Settings</h1>
                    <form onSubmit={handleSubmit} className="settings-form">

                    <label>
                           Name:
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            IFSC Code:
                            <input
                                type="text"
                                value={ifscCode}
                                onChange={(e) => setIfscCode(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Branch:
                            <input
                                type="text"
                                value={branch}
                                onChange={(e) => setBranch(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Account Number:
                            <input
                                type="text"
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                                required
                            />
                        </label>

                        <button type="submit" className="save-button">Save</button>

                        {error && <p className="error">{error}</p>}
                        {success && <p className="success">{success}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;

