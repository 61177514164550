  import React from 'react';
  import { useState } from 'react';
  import { useNavigate, useLocation } from 'react-router-dom';
  import Header from '../components/Header';
  import '../style/Product.css';
  import Sidebar from '../components/Sidebar';

  const products = [
    
    {
      id: 1,
      name: 'White Oversized T-shirt',
      title: 'whot',
      images: ['white190.png', '190WhiteB.jpg'],
      description: 'White Oversized T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 235,
      tax: 'tax',
      mockups: ['white190.png', '190WhiteB.jpg'],
      texture: '21.png',
      GSM: '190 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },

      clippingRects: {
        front: { left: 600, top: 670, width: 1020, height: 1400 },
        back: { left: 570, top: 670, width: 1020, height: 1400 }
      },

      // clippingRects: {
      //   front: { left: 108, top: 138, width: 220, height: 300 },
      //   back: { left: 115, top: 130, width: 210, height: 300 },
      // },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'190-regular.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },
    
    
    {
      id: 2,
      name: 'White Oversized T-shirt',
      title: 'whot',
      images: ['white220.png', '220WhiteB.jpg'],
      description: 'White Oversized T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 250,
      tax: 'tax',
      mockups: ['white220.png', '220WhiteB.jpg'],
      texture: '21.png',
      GSM: '220 GSM',
      costStructure:{

        'DTG Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
       'DTG Brother':{
        logo: 60,
        LessthenA4: 104,
        A4: 130,
        A4less: 200,
        A3: 200,
      full: 250,
      }
      } ,
      clippingRects: {
        front: { left: 580, top: 670, width: 1040, height: 1500 },
        back: { left: 600, top: 670, width: 1020, height: 1400 },
      },    
      print_method: ["DTG Epson", "DTF Epson","DTG Brother"],
      sizeChart:'oversized-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL']
    },



    {
      id: 3,
      name: 'White Oversized T-shirt',
      title: 'whot',
      images: ['whiteF5.png', '6.png'],
      description: 'White Oversized T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 280,
      tax: 'tax',
      mockups: ['whiteF5.png', '6.png'],
      texture: '21.png',
      GSM: '240 GSM',
      costStructure: { 
        'DTG Brother':{
        logo: 60,
        LessthenA4: 104,
        A4: 130,
        A4less: 200,
        A3: 200,
      full: 250,
      }},
      clippingRects: {
        front: { left: 540, top: 690, width: 1030, height: 1440 },
        back: { left: 580, top: 690, width: 1030, height: 1440 },
      },
      print_method: ["DTG Brother"],
      sizeChart:'oversized-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL']
    },

    {
      id: 4,
      name: 'Black Oversized T-shirt',
      title: 'whot',
      images: ['black190.png', '190BlackB.jpg'],
      description: 'Black Oversized T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 235,
      tax: 'tax',
      mockups: ['black190.png', '190BlackB.jpg'],
      texture: '21.png',
      GSM: '190 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: { left: 600, top: 670, width: 1020, height: 1400 },
        back: { left: 600, top: 670, width: 1020, height: 1400 }
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'190-regular.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },


    {
      id: 5,
      name: 'Black Oversized T-shirt',
      title: 'whot',
      images: ['black220.png', '220BlackB.jpg'],
      description: 'Black Oversized T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 250,
      tax: 'tax',
      mockups: ['black220.png', '220BlackB.jpg'],
      texture: '21.png',
      GSM: '220 GSM',
      costStructure: { 
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
        'DTG Brother':{
        logo: 60,
        LessthenA4: 104,
        A4: 175,
        A4less: 250,
        A3: 250,
      full: 300,
      }},
      clippingRects: {
        front: { left: 580, top: 670, width: 1040, height: 1500 },
        back: { left: 600, top: 670, width: 1020, height: 1400 },
      },
      print_method: ["DTG Epson", "DTF Epson", "DTG Brother"],
      sizeChart:'oversized-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },



    {
      id: 6,
      name: 'Black Oversized T-shirt',
      title: 'whot',
      images: ['blackF1.png', '3.png'],
      description: 'Black Oversized T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 280,
      tax: 'tax',
      mockups: ['blackF1.png', '3.png'],
      texture: '21.png',
      GSM: '240 GSM',
      costStructure: {'DTG Brother':{
        logo: 60,
        LessthenA4: 104,
        A4: 175,
        A4less: 250,
        A3: 250,
      full: 300,
      }},
      clippingRects: {
        front: { left: 540, top: 690, width: 1030, height: 1440 },
        back: { left: 580, top: 690, width: 1030, height: 1440 },
      },
      print_method: ["DTG Brother"],
      sizeChart:'oversized-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL']
    },


    {
      id: 7,
      name: 'Black Regular Fit T-shirt',
      title: 'wlot',
      images: ['BlackFitF.png', 'BlackFitB.png'],
      description: 'Black Regular Fit T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['BlackFitF.png', 'BlackFitB.png'],
      texture: '21.png',
      GSM: '180 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: { left: 600, top: 750, width: 1000, height: 1300 },
        back: { left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'regularfit-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },



    {
      id: 8,
      name: 'White Regular Fit T-shirt',
      title: 'wlot',
      images: ['WhiteFitF.png', 'WhiteFitB.png'],
      description: 'White Regular Fit T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['WhiteFitF.png', 'WhiteFitB.png'],
      texture: '21.png',
      GSM: '180 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
           A4: 90,
           A3: 120,
          full: 150,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: {left: 600, top: 750, width: 1000, height: 1300 },
        back: { left: 620, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'regularfit-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },

    {
      id: 9,
      name: 'Red Regular Fit T-shirt',
      title: 'wlot',
      images: ['RedFitF.png', 'RedFitB.png'],
      description: 'Red Regular Fit T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['RedFitF.png', 'RedFitB.png'],
      texture: '21.png',
      GSM: '180 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: { left: 600, top: 700, width: 1000, height: 1300 },
        back: { left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'regularfit-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },

    {
      id: 10,
      name: 'Navy Blue Regular Fit T-shirt',
      title: 'wlot',
      images: ['NavyBlueFitF.png', 'NavyBlueFitB.png'],
      description: 'Navy Blue Regular Fit T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['NavyBlueFitF.png', 'NavyBlueFitB.png'],
      texture: '21.png',
      GSM: '180 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: { left: 600, top: 700, width: 1000, height: 1300 },
        back: {left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'regularfit-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },

    {
      id: 11,
      name: 'Blue Regular Fit T-shirt',
      title: 'wlot',
      images: ['BlueFitF.png', 'BlueFitB.png'],
      description: 'Blue Regular Fit T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['BlueFitF.png', 'BlueFitB.png'],
      texture: '21.png',
      GSM: '180 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: { left: 580, top: 700, width: 1000, height: 1300 },
        back: { left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'regularfit-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },

    
    {
      id: 12,
      name: 'Brown Regular Fit T-shirt',
      title: 'wlot',
      images: ['BrownFitF.png', 'BrownFitB.png'],
      description: 'Blue Regular Fit T-shirt',
      fabric: '100% Cotton',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['BrownFitF.png', 'BrownFitB.png'],
      texture: '21.png',
      GSM: '180 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
      },
      clippingRects: {
        front: { left: 600, top: 700, width: 1000, height: 1300 },
        back: { left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'regularfit-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL', 'XXL']
    },  


    {
      id: 13,
      name: 'Black Baby Tee',
      title: 'baby',
      images: ['BabyTeeB.png','BabyTeeB.png'],
      description: 'Baby Tee',
      fabric: '95% cotton and 5% lycra',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['BabyTeeB.png','BabyTeeB.png'],
      texture: '21.png',
      GSM: '190 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4: 114,
          full: 130,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4: 90,
          full: 102,
        },
      },
      clippingRects: {
        front: { left: 588, top: 800, width: 1000, height: 1200 },
        back: { left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'babyTee-SizeChart.jpg',
      sizes : ['S', 'M', 'L', 'XL']
    },  



    {
      id: 14,
      name: 'White Baby Tee',
      title: 'baby',
      images: ['BabyTeeW.png','BabyTeeW.png' ],
      description: 'Baby Tee',
      fabric: '95% cotton and 5% lycra',
      BASE_COST: 180,
      tax: 'tax',
      mockups: ['BabyTeeW.png','BabyTeeW.png'],
      texture: '21.png',
      GSM: '190 GSM',
      costStructure: {
        'DTG Epson': {
          logo: 36,
          LessthenA4: 66,
          A4: 90,
          full: 102,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4: 90,
          full: 102,
        },
      },
      clippingRects: {
        front: { left: 588, top: 800, width: 1000, height: 1200 },
        back: { left: 600, top: 680, width: 1000, height: 1300 },
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'babyTee-SizeChart.jpg',
      sizes : ['S', 'M', 'L', 'XL']
    },  
  ];


  const SelectProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get('shop');
    const state = location.state;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    // Filter products based on selected t-shirt ID
    let filteredProducts = [];

    if (state?.id === 1) {
      filteredProducts = products.filter(product => product.title === 'whot');
    // } else if (state?.id === 2) {
    //   filteredProducts = products.filter(product => product.title === 'blot');
    } else if (state?.id === 2) {
      filteredProducts = products.filter(product => product.title === 'wlot');
    } else if (state?.id === 3) {
      filteredProducts = products.filter(product => product.title === 'baby');
    } else {
      filteredProducts = products; // default to show all if no ID is provided
    }

    const handleProductClick = (product) => {
 
        
        navigate('/DesignUpload', { state: { product, costStructure: product.costStructure, BASE_COST: product.BASE_COST, name: product.name, GSM: product.GSM , tax: product.tax,clippingRects: product.clippingRects, sizeChart: product.sizeChart, print_method: product.print_method, sizes:product.sizes } });
  
    };

    return (
      <div className="dashboard">
        <Header />
        <div className="dashboard-container">
          <Sidebar />
          <div className="main-content">
            <div className="product-list">
              {filteredProducts.map((product) => (
                <div
                  key={product.id}
                  className="product-card"
                  onClick={() => handleProductClick(product)}
                >
                  <img src={product.images[0]} alt={product.name} className="product-image" />
                  <p className="product-description">{product.description}</p>
                  <p className="product-description">{product.fabric}</p>
                  <p className="product-description">Base Cost: ₹{product.BASE_COST} + {product.tax} </p>
                  <p className="product-description">{product.GSM}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default SelectProduct;

// const SelectProduct = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const shop = queryParams.get('shop');
//   const state = location.state;
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   const filteredProducts = state?.color
//     ? products.filter(product =>
//         (state.color === 'white' && product.title === 'whot') ||
//         (state.color === 'black' && product.title === 'blot')
//       )
//     : products;

//   const handleProductClick = (product) => {
//     if (shop) {
//       navigate(`/DesignUpload?shop=${encodeURIComponent(shop)}`, {
//         state: {
//           product,
//           costStructure: product.costStructure,
//           BASE_COST: product.BASE_COST,
//           name: product.name,
//           GSM: product.GSM,
//           tax: product.tax,
//           images: product.images, // Pass the mockup images
//         }
//       });
//     } else {
//       console.error('Shop parameter is missing');
//     }
//   };

//   return (
//     <div className="dashboard">
//       <Header />
//       <div className="dashboard-container">
//         <Sidebar />
//         <div className="main-content">
//           <div className="product-list">
//             {filteredProducts.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)}
//               >
//                 {product.images.map((images, index) => (
//                   <img
//                     key={index}
//                     src={images}
//                     alt={`${product.name} images ${index + 1}`}
//                     className="product-image"
//                   />
//                 ))}
//                 <p className="product-description">{product.description}</p>
//                 <p className="product-description">{product.fabric}</p>
//                 <p className="product-description">
//                   Base Cost: ₹{product.BASE_COST} + {product.tax}
//                 </p>
//                 <p className="product-description">{product.GSM}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SelectProduct;