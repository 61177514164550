import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import '../style/PrivacyPolicy.css'; // Reuse PrivacyPolicy.css for consistency

const ShippingPolicy = () => {
  return (
    <div className="privacy-page">
      <Header />
      <div className="privacy-container">
        <Sidebar />
        <div className="main-content">
          <h1>Shipping Policy</h1>

          <p>
          We offer a standard shipping rate of ₹65 all over India, with a few exceptions.  
          </p>

          <p>
          For certain regions, such as North East India, Jammu & Kashmir, Ladakh, Himachal Pradesh, Sikkim, Andaman & Nicobar Islands, and Lakshadweep, an additional charge of ₹25 is applied due to higher logistics costs.  
          </p>

          <p>
          The shipping rates apply to orders weighing up to 500 grams.
          </p>

          <p>
          To ensure timely deliveries and reduce the risk of RTOs, we work exclusively with the best shipping partners in the industry. Orders placed before 5 PM are dispatched the very next day, while orders placed after 5 PM are scheduled for dispatch the following day.  
          </p>

          <p>
          Please note that our unit is not operational on Sundays and bank holidays, which may result in minor delays in dispatch.  

          </p>

          {/* <p>
          For Cash on Delivery (COD) orders, an additional charge of ₹40 or 2% of the order value (whichever is higher) will be added to the base shipping rate.  
          </p> */}

  
          <p>
          We are dedicated to providing a seamless and reliable delivery experience across India!
          </p>

        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;


