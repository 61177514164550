import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase'; // Ensure you're importing auth if needed
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useLocation } from 'react-router-dom'; // Use useLocation for query parameters
import axios from 'axios'; // For making API requests
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const PaidOrdersPage = () => {
    const [paidOrders, setPaidOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [trackingUrls, setTrackingUrls] = useState({}); // Store tracking URLs
    const location = useLocation(); // Get the current location
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop'); // Retrieve shopId from query parameters

      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not logged in");
      }

    // Fetch paid orders from Firestore
    const fetchPaidOrders = async () => {
        // if (!shopId) {
        //     console.error("Shop ID is missing");
        //     setLoading(false);
        //     return;
        // }

        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const q = query(ordersCollection, where('isPaid', '==', true));
            const ordersSnapshot = await getDocs(q);
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPaidOrders(ordersList);

            // Fetch tracking URLs for each order once orders are loaded
            const newTrackingUrls = {};
            for (const order of ordersList) {
                const trackingUrl = await fetchTrackingUrl(order.id);
                newTrackingUrls[order.id] = trackingUrl;
            }
            setTrackingUrls(newTrackingUrls);

        } catch (error) {
            console.error("Error fetching paid orders:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch tracking URL from the backend
    const fetchTrackingUrl = async (orderId) => {
        try {
            const response = await axios.get(
                `https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`
            );
            if (response.data && response.data.tracking_url) {
                return response.data.tracking_url;
            }
        } catch (error) {
            console.error("Error fetching tracking URL for order", orderId, error);
            return null;
        }
        return null;
    };

    // Handle invoice download
    const handleDownloadInvoice = (invoiceUrl) => {
        if (invoiceUrl) {
            window.open(invoiceUrl, '_blank');
        } else {
            alert('Invoice not available for this order.');
        }
    };

    // Load paid orders on component mount
    useEffect(() => {
        fetchPaidOrders();
    }, [shopId]);

    if (loading) {
        return <div>Loading paid orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Paid Orders</h1>
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Unitee Order ID</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Invoice</th>
                                <th>Tracking URL</th> {/* New column for Tracking URL */}
                            </tr>
                        </thead>
                        <tbody>
                            {paidOrders.map((order) => (
                                <tr key={order.id}>

                             <td>{order.Storeorder_id || 'NA'}</td>
                                    <td>{order.id || 'NA'}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {/* Check if invoice URL exists and add the button */}
                                        {order.invoiceUrl ? (
                                            <button 
                                                onClick={() => handleDownloadInvoice(order.invoiceUrl)} 
                                                className="download-invoice-btn"
                                            >
                                                Download Invoice
                                            </button>
                                        ) : (
                                            <span>No Invoice</span>
                                        )}
                                    </td>
                                    <td>
                                     
                                        {trackingUrls[order.id] ? (
                                            <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
                                                Track Order
                                            </a>
                                        ) : (
                                            <span>Printing Started</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PaidOrdersPage;

