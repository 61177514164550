// src/components/AuthRoute.js
// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { auth } from '../firebase'; // Adjust the path based on your project structure

// const AuthRoute = ({ element: Component }) => {
//   const user = auth.currentUser; // Get the current user

//   // If user is authenticated, render the component; otherwise, redirect to login
//   return user ? <Component /> : <Navigate to="/" />;
// };

// export default AuthRoute;

// src/components/AuthRoute.js
// import React from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { auth } from '../firebase'; // Adjust the path based on your project structure

// const AuthRoute = ({ element: Component, redirectPath = '/' }) => {
//   const user = auth.currentUser; // Get the current user
//   const location = useLocation(); // Access the location object
//   const queryParams = new URLSearchParams(location.search);
//   const shop = queryParams.get('shop'); // Get the shop parameter

//   // If user is authenticated, render the component; otherwise, redirect to login
//   if (user) {
//     return <Component />;
//   } else {
//     // Construct the redirect URL with the shop parameter
//     const redirectTo = shop ? `${redirectPath}?shop=${encodeURIComponent(shop)}` : redirectPath;
//     return <Navigate to={redirectTo} />;
//   }
// };

// export default AuthRoute;


import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the path based on your project structure

const AuthRoute = ({ element: Component, redirectPath = '/' }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const location = useLocation(); // Access the location object
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop'); // Get the shop parameter

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false); // Set loading to false once the auth state is determined
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, []);

  if (loading) {
    // You can return a loading spinner or some placeholder while checking auth state
    return <div>Loading...</div>;
  }

  // If user is authenticated, render the component; otherwise, redirect to login
  if (user) {
    return <Component />;
  } else {
    // Construct the redirect URL with the shop parameter
    const redirectTo = shop ? `${redirectPath}?shop=${encodeURIComponent(shop)}` : redirectPath;
    return <Navigate to={redirectTo} />;
  }
};

export default AuthRoute;


